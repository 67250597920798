import NextLink, { type LinkProps as NextLinkProps } from 'next/link';
import styled, { css } from 'styled-components';
import { createContext, useContext, type ReactNode } from 'react';

import {
  isLinkInternal,
  parseUrlToMatchEnvironment,
  shouldLinkOpenInNewTab,
} from '@yoweb/utils/common';

export const IDS = {
  internal: 'internal-store-link',
  external: 'external-store-link',
};

interface LinkProps extends NextLinkProps {
  className?: string;
  hasUnderline?: boolean;
  shouldOpenInTab?: boolean;
  children?: ReactNode;
  'data-testid'?: string;
}

export type LinkContextValue = {
  baseUrl: string;
};

export const LinkContext = createContext<string | undefined>(undefined);

export type LinkProviderProps = {
  children?: ReactNode;
  baseUrl: string;
};

export const LinkProvider = ({ children, baseUrl }: LinkProviderProps) => (
  <LinkContext.Provider value={baseUrl}>{children}</LinkContext.Provider>
);

/** Next link that can handle external urls
 *  external urls are strings that start with http protocol
 */
export const Link = ({
  className,
  href,
  children,
  hasUnderline,
  shouldOpenInTab,
  'data-testid': dataTestId,
  ...linkProps
}: LinkProps) => {
  const baseUrl = useContext(LinkContext);

  const isInternal = !shouldOpenInTab && isLinkInternal(href, baseUrl);

  if (isInternal) {
    return (
      <NextLink href={href} passHref {...linkProps} legacyBehavior>
        <A
          href={href.toString()}
          className={className}
          data-testid={dataTestId || IDS.internal}
          $hasUnderline={hasUnderline}
        >
          {children}
        </A>
      </NextLink>
    );
  }

  const parsedHref = parseUrlToMatchEnvironment(baseUrl, href.toString());

  const openInNewTab =
    shouldOpenInTab || (!isInternal && shouldLinkOpenInNewTab(parsedHref.toString()));

  return (
    <A
      className={className}
      data-testid={dataTestId || IDS.external}
      href={parsedHref.toString()}
      rel={openInNewTab ? 'noopener noreferrer' : ''}
      target={openInNewTab ? '_blank' : ''}
      $hasUnderline={hasUnderline}
    >
      {children}
    </A>
  );
};

const A = styled.a<{ $hasUnderline?: boolean }>`
  ${(props) =>
    props.$hasUnderline &&
    css`
      text-decoration: underline;
    `}
`;
