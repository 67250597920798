import { StatusCodes } from 'http-status-codes';
import type { LinkProps } from 'next/link';

export class ApiError extends Error {
  status: number;
  statusText?: string;
  body?: unknown;

  constructor(
    message: string,
    status = StatusCodes.INTERNAL_SERVER_ERROR,
    statusText?: string,
    body?: unknown,
  ) {
    super(message);

    // Ensures that the callstack is maintained from where it was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.status = status;
    this.statusText = statusText;
    this.body = body;
  }
}

/**
 * Clamp a value between two values.
 * @return min <= x <= max
 */
export const clamp = (min: number, max: number, x: number): number =>
  Math.max(min, Math.min(x, max));

export const runMocks = process.env.NEXT_PUBLIC_API_MOCKS === 'true';

export const isLinkInternal = (url: LinkProps['href'], baseUrl?: string): boolean => {
  if (typeof url !== 'string') {
    return true;
  }

  return Boolean(
    url &&
      !(
        (baseUrl && !url.includes(baseUrl) && url.startsWith('http')) ||
        url.startsWith('tel') ||
        url.startsWith('mailto')
      ),
  );
};

export const shouldLinkOpenInNewTab = (href: string): boolean =>
  Boolean(href && !href.startsWith('tel'));

const whiteListedRoutes = ['login'];

/**
 * Changes the subdomain of a given URL based on the provided base URL.
 *
 * @param url - The original URL whose subdomain needs to be changed.
 * @param baseUrl - The base URL used to determine the new subdomain.
 * @returns The modified URL with the new subdomain.
 *
 * @remarks
 * - If the original URL has more than two parts in its host (e.g., `sub.example.com`),
 *   and the subdomain is 'www', the subdomain is modified by appending the environment part
 *   of the base URL after splitting it by '-'.
 * - If the original URL has two or fewer parts in its host (e.g., `example.com`),
 *   a new subdomain is added by prepending 'www-' followed by the environment part of the
 *   base URL after splitting it by '-'.
 *
 * @example
 * ```typescript
 * const newUrl = changeUrlSubdomain('https://www.yohana.com', 'https://app-dev.yohana.com');
 * console.log(newUrl); // 'http://www-dev.yohana.com'
 * ```
 */
export const changeUrlSubdomain = (url: string, baseUrl: string): string => {
  if (!new URL(baseUrl).host.includes('-')) {
    return url;
  }

  const parsedUrl = new URL(url);
  const parts = parsedUrl.host.split('.');
  const baseUrlParts = baseUrl.split('-');
  const env = baseUrlParts[1].split('.')[0];

  if (parts.length > 2) {
    const subdomain = parts[0];
    if (subdomain === 'www') {
      parts[0] = `${subdomain}-${env}`;
    }
  } else {
    parts.unshift(`www-${env}`);
  }
  parsedUrl.host = parts.join('.');
  return parsedUrl.toString();
};

/**
 * Parses a given URL to match the environment's hostname and protocol.
 *
 * @param {string} [baseUrl] - The base URL to use for determining the environment's hostname.
 * @param {string} [url] - The URL to be parsed and potentially modified.
 * @returns {URL | string} - The modified URL object or the original URL string if no modifications are needed.
 *
 */
export const parseUrlToMatchEnvironment = (baseUrl?: string, url?: string): URL | string => {
  if (!url) {
    return '';
  }

  // If the URL is internal or does not contain "yohana" or it's the CDN, return the original URL.
  if (
    isLinkInternal(url, baseUrl) ||
    !new URL(url).host.includes('yohana') ||
    new URL(url).host.includes('cdn.prismic')
  ) {
    return url;
  }

  // If the URL's pathname matches any of the whitelisted routes, return the original URL.
  const parsedUrl = new URL(url);
  if (whiteListedRoutes.some((route) => parsedUrl.pathname.includes(route))) {
    return url;
  }

  if (baseUrl) {
    const baseUrlObj = new URL(baseUrl);
    if (baseUrlObj.host.includes('app')) {
      if (baseUrlObj.host.includes('-')) {
        return changeUrlSubdomain(url, baseUrl);
      }
      return url;
    }
  }

  let hostname = '';
  if (typeof window !== 'undefined') {
    hostname = window.location.host;
  } else if (baseUrl) {
    hostname = new URL(baseUrl).host;
  }

  if (hostname) {
    parsedUrl.host = hostname;
  }

  if (baseUrl?.includes('localhost')) {
    parsedUrl.protocol = 'http';
  }

  return parsedUrl;
};

export const fetcher = async <T>(url: string, nocache = false): Promise<T> => {
  const res = await fetch(
    url,
    nocache
      ? {
          headers: {
            'Cache-Control': 'no-cache',
          },
        }
      : undefined,
  );

  if (!res.ok) {
    const error = new Error(
      `An error occurred while fetching data. Status: ${res.status} URL: "${res.url}"`,
    );

    // attach extra info to the error object.
    try {
      error.info = await res.json();
    } catch (e) {
      // TODO: handle error
    }

    error.status = res.status;

    throw error;
  }

  return res.json();
};
